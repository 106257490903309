import type { MenuItemProps } from "design-system-old";

export const addItemsInContextMenu = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  args: boolean[],
  history: any,
  workspaceId: string,
  moreActionItems: MenuItemProps[],
) => {
  return moreActionItems;
};

export const useHtmlPageTitle = () => {
  return "Pageplug";
};

export const isCEMode = () => {
  return true;
};
